import { Box } from '@rebass/grid'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Line from './Line'

const Pagination = ({ swiper, currentIndex, setCurrentIndex }) => {
  const swiperSlidesLength = swiper?.slides?.length || 0

  return (
    <Wrapper>
      <LineWrapper>
        {Array.from({ length: swiperSlidesLength }).map((_, index) => (
          <Line
            swiper={swiper}
            setCurrentIndex={setCurrentIndex}
            key={index}
            isCurrent={currentIndex === index}
            index={index}
          />
        ))}
      </LineWrapper>
    </Wrapper>
  )
}

export default Pagination

const Wrapper = styled(Box)`
  padding-left: 80px;
  padding-right: 80px;
  margin-bottom: 40px;
  width: 100%;

  @media (max-width: 800px) {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;

    position: absolute;

    top: 30px;
  }
  z-index: 999999;
`

const LineWrapper = styled(Box)`
  display: flex;
  gap: 4px;
`
