import React from 'react'
import SlideImage from './SlideImage'
import styled from 'styled-components'
import { Box } from '@rebass/grid'
import SlideVideo from './SlideVideo'

const Slide = ({ data, currentIndex, index }) => {
  return (
    <Wrapper className="coverflow-slide-inner-wrapper">
      {data.type === 'image' && <SlideImage data={data} />}
      {data.type === 'video' && (
        <SlideVideo currentIndex={currentIndex} index={index} data={data} />
      )}
    </Wrapper>
  )
}

export default Slide

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`
