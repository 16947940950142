import { Box } from '@rebass/grid'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../../../../util/style'

const Copy = ({ children, currentIndex }) => {
  const [hide, setHide] = useState(false)

  useEffect(() => {
    setHide(currentIndex > 0)
  }, [currentIndex])

  return <Wrapper hide={hide}>{children}</Wrapper>
}

export default Copy

const Wrapper = styled(Box)`
  position: absolute;
  margin-left: 80px;
  width: 360px;
  font-size: 40px;
  line-height: 130%;
  color: white;

  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.25s ease-in-out;

  font-family: ${theme.font.fontFamilySerifThin};

  @media (max-width: 800px) {
    display: none;
  }
`
