import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'

const SlideImage = ({ data }) => {
  return (
    <Wrapper>
      <img src={data?.src} alt={data?.alt} />
    </Wrapper>
  )
}

export default SlideImage

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
