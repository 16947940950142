import { OutlineButton, theme } from '../util/style'
import React, { Component } from 'react'

import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogCopyRow from '../components/blog-page/PageBlog/BlogCopyRow'
import BlogMediaRowFull from '../components/blog-page/PageBlog/BlogMediaRowFull'
import BlogQuoteRow from '../components/blog-page/PageBlog/BlogQuoteRow'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import BlogSubscribeRow from '../components/blog-page/PageBlog/BlogSubscribeRow'
import BlogTitleRow from '../components/blog-page/PageBlog/BlogTitleRow'
import { Box } from '@rebass/grid'
import CopyStackRow from '../components/v2/CopyStackRow'
import Coverflow from '../components/v2/Coverflow'
import Image1 from '../images/etc/happy-haus-hero-5.jpg'
import Image2 from '../images/etc/happy-haus-hero-8.jpg'
import { Link } from 'gatsby'
import PageHeader from '../components/v2/PageHeader'
import SEO from '../components/seo'
import slug from 'slug'
import styled from 'styled-components'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]

class Page extends Component {
  render() {
    return (
      <>
        <SEO title="Custom Haus" description="" images={ogImages} />
        <PageHeader title={'Custom Haus'} />
        <BlogContainer borderTop pb={['36px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    If your brief or site is unique or you wish to pursue an
                    architectural vision, we will guide you through the custom
                    haus journey.
                  </p>
                </>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogMediaRowFull
            data={{
              mediaCaption: {
                mediaCaption: <>James Haus, New Farm.</>,
              },
              images: [
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-02-15-photo-tarragindi-1110199.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110812.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2022-09-20-photo-orari-JG-1080086.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100668.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-05-13-photo-deloraine-1140997.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010573.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100640.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-01-22-photo-gordon-1100319.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-photo-ocean-shores-JG-1090038.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2023-07-28-photo-welbeck-1010088-2.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
              ],
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              images: [
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-15-photo-tarragindi-1110214.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110741.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100748.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-photo-bardon-AM-2-2.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010433.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-05-23-photo-olive-1150570.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110878.jpg',
                  },
                },
              ],

              copyHtml: (
                <div>
                  <p>
                    Our considered approach guides a haus along the right path
                    to becoming your home. In some instances, the right home is
                    a custom design. Some clients may be wary of the potential
                    challenges associated with custom-designed homes, however
                    our process ensures it is still a Happy Haus.
                  </p>
                  <p>
                    While the design process may be a little more involved
                    upfront, the same principles and stages of design are
                    applied to the project to make this a seamless design +
                    build experience. Typically, we are engaged on custom
                    projects for two main reasons: our clients have a block that
                    is unique in one or many aspects, or have a brief for their
                    home that has unique aspects.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              reverse: true,
              images: [
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010441.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.JPG',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-05-13-photo-deloraine-1150158.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-01-22-photo-gordon-1100340.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110898.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-01-22-photo-gordon-1100335.jpg',
                  },
                },
              ],
              copyHtml: (
                <div>
                  <p>
                    Some of our all-time, favourite projects have come from the
                    custom haus process. The partnership with clients is highly
                    rewarding as we work together to realise our clients'
                    aspirations for a Happy Haus life.
                  </p>
                  <p>
                    From initial consultation to final sign-off, we work in
                    partnership with our clients to ensure the creation of their
                    homes and the delivery of an unbeatable experience. To learn
                    about the process we've put together a{' '}
                    <Link to="/guide-custom-haus/">custom haus guide</Link> with
                    expert insights on what to think about when considering a
                    architectural commission.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogSubscribeRow
            data={{
              image: {
                file: { path: '/images/custom-haus/subscribe-graphic.png' },
              },
              copyHtml: 'Learn about the custom haus process',
              buttonText: 'Download',
              theme: 'Subtropical Homes',
              hubspotConversionType: 'Custom Haus Guide',
            }}
          />
        </BlogContainer>
        <BlogContainer attachTop>
          <BlogMediaRowFull
            data={{
              style: 'inset',
              media: {
                file: {
                  path: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110986.jpg',
                },
              },
            }}
          />
        </BlogContainer>
        <BlogContainer attachTop pb={['46px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    If you’re considering a Happy Haus or about to embark on a
                    new haus project, we can’t wait to say,{' '}
                    <i>“Welcome home!”</i>
                  </p>
                  <h5>LACHLAN & RICK</h5>
                </>
              ),
            }}
          />
        </BlogContainer>
        <BlogRelated
          data={{
            relatedPosts: [
              {
                slug: 'top-sales-results-in-new-farm-and-tarragindi',
                title: 'Top sales results in New Farm and Tarragindi',
                category: ['Noteworthy'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.jpg',
                  },
                },
              },
              {
                title: 'Making more neighbourhood memories',
                slug: 'silex-haus-a-legacy-of-memories',
                category: ['Owners Club'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                  },
                },
              },
            ],
          }}
        />
      </>
    )
  }
}

export default Page

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`
