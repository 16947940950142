import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../util/style'
import CopyStackRow from '../CopyStackRow'

const PageHeader = ({ title, ...rest }) => {
  return (
    <Wrapper px={2}>
      <Title {...rest}>{title}</Title>
    </Wrapper>
  )
}

export default PageHeader

const Wrapper = styled(Box)``

const Title = styled(Box)`
  font-size: 70px;
  font-family: ${theme.font.fontFamilySerifThin};
  line-height: 100%;
  text-align: center;

  padding-top: 222px;
  padding-bottom: 288px;

  @media (max-width: 800px) {
    font-size: 50px;
    padding-top: 122px;
    padding-bottom: 164px;
  }
`
