import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'

const SlideVideo = ({ currentIndex, index, data, onSlideClick }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlay = () => {
    if (currentIndex === index) {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause()
        } else {
          videoRef.current.play()
          onSlideClick && onSlideClick(index) // Call the passed goToSlide function
        }
        setIsPlaying(!isPlaying)
      }
    }
  }

  useEffect(() => {
    if (currentIndex !== index) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }, [currentIndex, index])

  return (
    <Wrapper onClick={togglePlay}>
      {!isPlaying && (
        <PlayButton>
          <svg
            width="34"
            height="40"
            viewBox="0 0 34 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34 19.6032L0.095236 39.1782L0.0952377 0.0283188L34 19.6032Z"
              fill="white"
            />
          </svg>
        </PlayButton>
      )}
      <StyledVideo controls={false} ref={videoRef} poster={data?.poster}>
        <source src={data?.src} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    </Wrapper>
  )
}

export default SlideVideo

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
`
