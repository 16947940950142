import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'

const Line = ({ swiper, index, setCurrentIndex, isCurrent }) => {
  const handleClick = () => {
    swiper.slideTo(index)
    setCurrentIndex(index)
  }

  return (
    <Wrapper isCurrent={isCurrent}>
      <Hitbox onClick={handleClick} />
    </Wrapper>
  )
}

export default Line

const Wrapper = styled(Box)`
  background-color: ${({ isCurrent }) => (isCurrent ? `#D9D9D9` : `#818181`)};
  width: 100%;
  height: 2px;
  flex-shrink: 1;
  border-radius: 50px;
  position: relative;
`

const Hitbox = styled(Box)`
  padding: 10px;
  width: 100%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
`
