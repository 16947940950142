import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import CopyStack from '../CopyStack'

const CopyStackRow = ({ items = [], noBorder }) => {
  return (
    <Wrapper>
      {items &&
        items.map((data, index) => (
          <CopyStack
            key={`copy-stack-${index}`}
            data={data}
            noBorder={noBorder}
          />
        ))}
    </Wrapper>
  )
}

export default CopyStackRow

const Wrapper = styled(Box)``
