import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../util/style'
import BlogContent from '../../blog-page/PageBlog/BlogContent'

const CopyStack = ({ data }) => {
  return (
    <Wrapper noBorder={!!data?.noBorder}>
      <HeadingWrapper as="h1">{data.heading}</HeadingWrapper>
      <ContentWrapper>
        <BlogContent>{data.content}</BlogContent>
      </ContentWrapper>
    </Wrapper>
  )
}

export default CopyStack

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-top: ${({ noBorder }) =>
    noBorder ? 0 : `1px solid ${theme.colors.grey11}`};
  padding-top: 30px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  margin-top: 68px;

  &:first-of-type {
    margin-top: 0;
  }
`
const HeadingWrapper = styled(Box)`
  font-family: ${theme.font.fontFamilyBold};
  font-size: 20px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
  font-weight: normal;
  margin: 0;
`
const ContentWrapper = styled(Box)`
  p {
    font-size: 22px;
    @media (max-width: 800px) {
      font-size: 18px;
    }
  }
`
